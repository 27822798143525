(function ($) {
	$(function () {
		var $html = $( 'html' );
		var $sectionBody = $( '.section-body' );

		var cookieName = "ax-recommended";
		var recommendedIds = ($.cookie(cookieName) || '').split('&');
		var currentLanguage = $html.attr('lang');
		var currentSite = $html.attr('data-site');

		$sectionBody.on( 'click', '.hlStandards', function (e) {
			e.preventDefault();

			var activityRow = $(e.target).closest("tr");
			
			var sActID = activityRow.data("activity-short-id");
			var legacyID = activityRow.data("activity-legacy-id");
			var curActID = sActID;
			if( legacyID ) {
				curActID = legacyID;
			}

			Dialogs.Init('Standards', function (dialogData) {
				dialogData.Open({ optional: true, aID: curActID });
			});
		});

		$sectionBody.on( 'click', '.hlTextbook', function (e) {
			e.preventDefault();

			var activityRow = $(e.target).closest("tr");
			var sActID = activityRow.data("activity-short-id");
			var legacyID = activityRow.data("activity-legacy-id");
			var curActID = sActID;
			if( legacyID ) {
				curActID = legacyID;
			}

			Dialogs.Init('AlignTextbooks', function (dialogData) {
				dialogData.Open({ optional: true, aID: curActID });
			});
		});

		// Code for Recommendation/Likes
		$sectionBody.on( 'click', '.recommend_off, .recommend_on', function (e) {
		    var $t = $(e.target);

		    var activityRow = $t.closest("tr");
		    var activityId = activityRow.data("activity-short-id");		    
		    e.preventDefault();
		    var value = activityId + '=1';

		    var recommended = false;
		    $.each(recommendedIds, function () {
		        if (this == value) recommended = true;
		    });
		    var activityName = $(activityRow).children('td:first').children('h4:first').children('a:first').text()
		    if (!recommended) {
		    $.ajax({
		        type: "POST",
		        url: Services.GetUrl( "/Services/Downloads/ActivitiesRecommend.ashx" ),
		        data: { lang: currentLanguage, site: currentSite, activitySId: activityId, activityName: activityName },
		        success: function (data) {
		            
		            if (data.toString() == "true") {
		                var $span = $t.next().find("span");
		                var c = parseInt($span.text(), 10);
		                if (!isNaN(c)) $span.eq(0).text(c + 1);
		                else $span.eq(0).text(c = 1);
		            } else {
		                Dialogs.Init('Notify', function (dialogData) {
		                    dialogData.Open({ optional: true, title: 'Activities / Error', text: data.toString(), showCancel: false });
		                });
		            }
		            recommendedIds.push(value);
		            $.cookie(cookieName, recommendedIds.join('&'), { domain: window.location.host, path: '/',  secure: true });
		        }
		    });

		    $(this).closest( 'li' ).removeClass( 'recommend_off' ).addClass( 'recommend_on' );
		}
		});

		// does not fire for AJAX events
		$( '.recommend_off' )
		.each( function() {
			if( $.inArray( $(this).closest( 'tr' ).data( 'activity-short-id' ) + '=1', recommendedIds ) >= 0 ) {
				$(this).closest( 'li' ).removeClass( 'recommend_off' ).addClass( 'recommend_on' );
			}
		} );

		// Code for Save Activity
		$sectionBody.on( 'click', '.save_off, .save_on', function (e) {
			e.preventDefault();
			var li = $(e.target);
			var activityRow = li.closest("tr");
			var activityId = activityRow.data("activity-short-id");

			if ($html.hasClass('visitor-signed-in')) {
				showDialog();
			}
			else {
				Dialogs.SignIn.Open({ optional: false, callback: showDialog });
			}

			function showDialog() {
				if (li.hasClass('save_on')) {
					Dialogs.Init('Notify', function (dialogData) {
						dialogData.Open({ optional: false, title: 'Activities/Remove Activity', text: 'Activities/Remove Activity Mssg', showCancel: true, callback: function () {
							$.ajax({
								type: "POST",
								url: Services.GetUrl( "/Services/Downloads/TrackMyActivity.ashx" ),
								data: { lang: currentLanguage, site: currentSite, activitySId: activityId, mode: 'Unsave' },
								success: function (data) {
									if (data.toString() == "true") {
										li.removeClass('save_on').addClass('save_off');
										li.attr("title", "Save Activity");
										if ($html.hasClass('visitor-signed-out')) {
											location.reload();
										}
									}
									else {
										Dialogs.Init('Notify', function (dialogData) {
											dialogData.Open({ optional: true, title: 'Activities/Remove Activity', text: data.toString(), showCancel: false });
										});
									}
								}
							});
						}
						});
					});
				}
				else {
					Dialogs.Init('Notify', function (dialogData) {
						dialogData.Open({ optional: false, title: 'Activities/Save Activities', text: 'Activities/Activity Saved', showCancel: false, callback: function () {
							$.ajax({
								type: "POST",
								url: Services.GetUrl( "/Services/Downloads/TrackMyActivity.ashx" ),
								data: { lang: currentLanguage, site: currentSite, activitySId: activityId, mode: 'Save' },
								success: function (data) {
									if (data.toString() == "true") {
										li.removeClass('save_off').addClass('save_on');
										li.attr("title", "Remove Activity");
										if ($html.hasClass('visitor-signed-out')) {
											location.reload();
										}
									}
									else {
										Dialogs.Init('Notify', function (dialogData) {
											dialogData.Open({ optional: false, title: 'Activities/Save Activities', text: data.toString(), showCancel: false });
										});
									}
								}
							});
						}
						});
					});
				}
			}
		});

		// Code for Download
		$sectionBody.on( 'click', '.download', function (e) { 
			var activityRow = $(e.target).closest("tr");
			var activityId = activityRow.data("activity-short-id");

			setTimeout(function () {
				$.ajax({
					type: "POST",
					url: Services.GetUrl( "/Services/Activities/TrackDownload.ashx" ),
					data: { lang: currentLanguage, site: currentSite, activityid: activityId },
					success: function (data) {
						if (data.toString() != null) {
							/*Google Analytics : Track download event*/
							require( [ 'analytics' ], function( analytics ) {
								if($('html').attr('data-category') != undefined && $('html').attr('data-category') != ''){					
									analytics.trackEvent( $('html').attr('data-category'), 'Activities', data.toString() );									
								}
								else{									
									analytics.trackEvent( 'Downloads', 'Activities', data.toString() );
								}
								
							} );
						}
					}

				});
			}, 1000);

		});


		$sectionBody.on( 'click', '.activity-search-results-grp tbody tr.groupByHeader th.silverShade h3 a', function () {
			var tr = $(e.target).closest('tr.groupByHeader');
			tr.closest('h3').removeClass('collapsed').addClass('expanded');

			tr.siblings('tr.groupByHeader').each(function (index, e) {
				var siblingH3 = tr.siblings('tr.groupByHeader').find('h3');
				if (siblingH3.hasClass('expanded')) {
					siblingH3.removeClass('expanded').addClass('collapsed');
				}
			});
		});

	});
} (jQuery));